























import { Prop, Component, Vue } from 'vue-property-decorator'

@Component
export default class ModalLogOut extends Vue {
  @Prop({ default: 'modal-logout' }) private id!: string
  @Prop() private logoutMess!: string
  @Prop({ default: 3 }) private closeSeconds!: number

  backToLogin() {
    this.$router.push({ name: 'login' })
  }

  handlePreventEvent(event: any) {
    event.preventDefault()
    this.backToLogin()
  }

  autoClose() {
    clearTimeout()
    setTimeout(() => {
      this.$bvModal.hide(this.id)
    }, this.closeSeconds * 1000)
  }
}
